<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Страницы сайта</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Страница О нас</h5>
        </div>
      </div>
      <div class="card-body">
        <p>Настройки страницы</p>
        <a-form-model :model="form">
          <a-form-model-item label="Заголовок(ru)" required>
            <a-input  v-model="form.ru.name" />
          </a-form-model-item>
          <a-form-model-item label="Заголовок(ua)" required>
            <a-input  v-model="form.ua.name" />
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Описание(ru)" required>
            <quill-editor ref="quillEditor"  v-model="form.ru.description"></quill-editor>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Описание(ua)" required>
            <quill-editor ref="quillEditor"  v-model="form.ua.description"></quill-editor>
          </a-form-model-item>
        </a-form-model>
        <a-form-model :model="communities">
          <a-form-model-item label="Ссылка на канал" required>
            <a-input  v-model="communities.youtube" />
          </a-form-model-item>
          <a-form-model-item label="ID видео" required>
            <a-input  v-model="communities.video" />
          </a-form-model-item>
          <div v-for="(value, index) in communities.community_values" class="value_list" :key="value.id">
            <a-form-model-item label="Иконка" required>
              <div class="icon" :style="value.color">
                <img :src="value.icon" />
              </div>
            </a-form-model-item>
            <a-form-model-item label="Ссылка" required>
              <a-input  v-model="value.url" />
            </a-form-model-item>
            <a-form-model-item label="Статус" required>
              <a-input  v-model="value.active" />
            </a-form-model-item>
            <a-form-model-item label="Позиция" required>
              <a-input  v-model="value.position" />
            </a-form-model-item>
            <a-form-model-item label="Цвет" required>
              <v-input-colorpicker v-model="value.adequateColorValue" @input="changeColor($event, index)" />
            </a-form-model-item>
            <a-form-model-item label="Название(ru)" required>
              <a-input  v-model="value.ru.name" />
            </a-form-model-item>
            <a-form-model-item label="Название(ua)" required>
              <a-input  v-model="value.ua.name" />
            </a-form-model-item>
          </div>
        </a-form-model>
        <a-button class="ml-auto mb-5 d-block" type="primary" @click="submit" :disabled="isCheckedForm || isCheckedCommunity">
          Сохранить изменения
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import InputColorPicker from 'vue-native-color-picker'

export default {
  name: 'index',
  data() {
    return {
      form: {
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      },
      communities: {
        community_values: [],
      },
    }
  },
  computed: {
    isCheckedForm() {
      const form = this.form
      return !(form.ru.name && form.ua.name && form.ua.description && form.ru.description)
    },
    isCheckedCommunity() {
      const form = this.communities
      let isDisabled = false
      if (!form.youtube || !form.video) isDisabled = true

      for (const index in form.community_values) {
        if (typeof form.community_values[index] !== 'object' && !typeof form.community_values[index]) isDisabled = true

        for (const item in form.community_values[index]) {
          if (typeof form.community_values[index][item] !== 'object' && !`${form.community_values[index][item]}`) isDisabled = true

          if (typeof form.community_values[index][item] === 'object') {
            for (const prop in form.community_values[index][item]) {
              if (!form.community_values[index][item][prop]) isDisabled = true
            }
          }
        }
      }

      return isDisabled
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const data = (await this.$services.get('admin/about')).data.data.data

        data.communities.community_values = data.communities.community_values.map(item => {
          item.adequateColorValue = item.color.split(' ')[1].slice(0, 7)
          return item
        })

        this.form = data.block_pages[0]
        this.communities = data.communities
      } catch (e) {
        console.log(e)
      }
    },
    changeColor(color, index) {
      this.communities.community_values[index].color = `background-color: ${color};`
    },
    async submit() {
      try {
        await this.$services.put(`admin/block_pages/${this.form.id}`, this.form)
        await this.$services.put('admin/about/community', this.communities)
        await this.getData()
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: { quillEditor, 'v-input-colorpicker': InputColorPicker },
}
</script>

<style scoped lang="scss">
.ant-form {
  .value_list {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 24px;
  }
  .icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    img {
      width: auto;
      height: 100%;
      max-width: 30px;
      margin: 0 auto;
      display: block;
    }
  }
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
